import classNames from 'classnames/bind';

import type { ICourseFeedback } from 'services/course.service';
import Item from './Item/Item';
import classes from './SocialValidation.module.scss';

export default SocialValidation;

const cx = classNames.bind(classes);

interface IProps {
  feedbacks: ICourseFeedback[]
}

function SocialValidation({ feedbacks }: IProps) {
  return (
    <>
      <div className={cx({ section__title: true })} >100+ учеников любят flearn</div>
      <div className={classes.list}>
          <Item/>
      </div>
    </>
  );
}